import React, { useState, useEffect } from 'react'
import './App.css'
import { BrowserRouter, Route, NavLink, Switch } from 'react-router-dom'
import Home from './Home'
import Resources from './Resources'

function App() {
	let timer = 0

	let [state, setState] = useState([])

	useEffect(() => {
		setInterval(increaseTimer, 7000)
	}, [])

	function increaseTimer() {
		timer += 1
		console.log('timer', timer)
		setState([...state])
	}

	return (
		<BrowserRouter>
			<div className='navbarContainer'>
				<NavLink
					exact
					to='/'
					className='navbarLink'
					activeClassName='navbarLinkSelected'
					defaultValue
				>
					Home
				</NavLink>
				<NavLink
					exact
					to='/resources'
					className='navbarLink'
					activeClassName='navbarLinkSelected'
				>
					Resources
				</NavLink>
			</div>

			<Switch>
				<Route exact path='/'>
					<Home timer={timer} />
				</Route>
				<Route exact path='/resources'>
					<Resources />
				</Route>
			</Switch>
		</BrowserRouter>
	)
}

export default App
