import React, { useState, useEffect } from 'react'

let c1 = `#3dbdc2`,
	c2 = `#20fc8f`,
	c3 = `#885a89`,
	c4 = `#ed6a5e`,
	c5 = `#fc814a`

let mainArray = [
	{
		id: 1,
		shortInfo: 'Only ~13% of illicit drug users have a substance use disorder',
		fullInfo: `The United Nations Office on Drugs and Crime recently published their 2020 World Drug Report, which states that “among the estimated 269 million people who used drugs in the past year, some 35.6 million people (range: 19.0 million to 52.2 million) are estimated to suffer from drug use disorders, meaning that their pattern of drug use is harmful, or they may experience drug dependence and/or require treatment.” \n While this is certainly a large number of people, the percentage of problematic users is relatively low, with over 80% of users using in moderation and without adverse societal consequences. This information clearly deviates from the more popular narrative that drugs are inherently a problem, or that there is no such thing as responsible use.`,
		link: 'https://wdr.unodc.org/wdr2020/field/WDR20_Booklet_2.pdf',
		linkName: "United Nations' Office of Drugs and Crimes (p. 11)",
		color: c1
	},
	{
		id: 2,
		shortInfo:
			'Fentanyl is often sold under the guise of other drugs, and has increasingly contributed to overdoses',
		fullInfo: `Fentanyl is a synthetic opioid which is incredibly potent (50X stronger than heroin). \n According to the National Institute on Drug Abuse (NIDA), “some drug dealers are mixing fentanyl with other drugs, such as heroin, cocaine, methamphetamine, and MDMA. This is because it takes very little to produce a high with fentanyl, making it a cheaper option. This is especially risky when people taking drugs don’t realize they might contain fentanyl as a cheap but dangerous additive. They might be taking stronger opioids than their bodies are used to and can be more likely to overdose.” \n Because users are unaware of this adulteration, “synthetic opioids, including fentanyl, are now the most common drugs involved in drug overdose deaths in the United States. In 2017, 59.8 percent of opioid-related deaths involved fentanyl compared to 14.3 percent in 2010.”`,
		link: `https://www.drugabuse.gov/publications/drugfacts/fentanyl`,
		linkName: `National Institute on Drug Abuse`,
		color: c2
	},
	{
		id: 3,
		shortInfo: `The way you consume a drug heavily influences the length of time it takes to become addicted`,
		fullInfo: `In more academic terms, the route of administration (ROA) is the form in which your body absorbs a given drug. Common ROAs include oral ingestion, smoking, snorting, and intravenous injection. \n These ROAs determine how long a drug takes to get to your brain. The slowest of these is ingesting, like taking an edible. After that is snorting, followed by smoking, which is faster acting due to blood vessels in the lungs which absorb the drug quickly. Intravenous injection is the fastest ROA, as the drug flows directly to your brain via your bloodstream. \n What is important to understand about ROAs is that they influence a drug’s addiction potential. This means that “the more quickly a drug is delivered to the brain, the more likely it will lead to dependence. Consequently, the routes of injecting and smoking which deliver drugs relatively quickly are considered more addictive than the routes of snorting or swallowing, which deliver the drugs more slowly.”
        `,
		link: `https://methoide.fcm.arizona.edu/infocenter/index.cfm?stid=208`,
		linkName: `MethOIDE`,
		color: c3
	},
	{
		id: 4,
		shortInfo: `Crack is chemically identical to powder cocaine`,
		fullInfo: `Despite the remarkable difference in which society views and treats these drugs, they are "chemically the same and affect the user in the same physical and psychological ways.” Crack is simply cocaine cut with baking soda and cooked into a rock form. The one major difference between their use is the route of administration, which is typically snorting for powder cocaine and smoking for crack cocaine. Smoking delivers the drug to the brain faster and leaves it faster as well. This makes crack more immediately intense for the user, and also makes the craving for ‘another hit’ stronger. \n However, society’s demonization of crack far outweighs its powder cocaine counterpart. For decades possession of crack was treated with a 100 to 1 ratio compared to powder cocaine, meaning that you would need 100 times more powder cocaine to face the same punishment as someone with crack. In 2010, the ‘Fair Sentencing Act’ reduced that to an 18:1 ratio, but the inequality remains. \n A primary reason that crack is targeted much more harshly than powder cocaine is the user base, which has been associated with African Americans for years. This allows for racial persecution and mass incarceration through the proxy of a ‘war on drugs.’
        `,
		link: `https://www.criminaldefenselawyer.com/resources/crack-vrs-powder-cocaine-one-drug-two-penalties.htm `,
		linkName: `CriminalDefenseLawyer`,
		color: c4
	},
	{
		id: 5,
		shortInfo: `Addiction is primarily driven by social and environmental factors, as opposed to ‘chemical hooks’`,
		fullInfo: `While it is true that your body can quickly develop a chemical dependency on many drugs, addiction is not quite that simple. In fact, studies have shown that other factors are much more important when determining the likelihood of addiction. In particular, childhood trauma and/or a damaging social environment have been correlated with substance abuse. \n Bruce Alexander was one of the first to realize the mistake of having a purely physical understanding of addiction. He conducted an incredibly interesting study, known to many as “Rat Park.” This experiment expanded on the already established observation that rats locked in an isolated cage would drink drugged water until they overdosed. Alexander recreated this experiment, yet also introduced a second cage with a socially healthy environment. Complete with the company of other rats and activities to do, the rats rarely ever took the drugged water, and none of them overdosed. \n A video explaining this experiment and more about the real causes of addiction is linked below. It also explains a related phenomenon that occurred with heroin addicted soldiers during the Vietnam War, 95% of whom quit easily when they returned home.
        `,
		link: `https://www.youtube.com/watch?v=PY9DcIMGxMs`,
		linkName: `Johann Hari TED Talk`,
		color: c5
	},
	{
		id: 6,
		shortInfo: `The scheduling of drugs is not purely based on how harmful they are`,
		fullInfo: `The DEA defines Schedule I drugs as “drugs with no currently accepted medical use and a high potential for abuse.” Included in this list are Ecstacy, LSD, and even Marijuana (despite its proven medicinal value). In addition, Schedule I and II drugs have the harshest punishment, as you “risk being sentenced to as many as 15 years in prison and a fine of up to $250,000.” \n Interestingly, when Marijuana was classified as Schedule I it was meant to be temporary, and was supposed to be officially classified “after debate on its medicinal possibilities.” Since then, “the agency has ignored repeated petitions to remove marijuana from Schedule I.”`,
		link: `https://www.pittsburghcriminalattorney.com/drug-crimes/schedule-i-ii-drugs/#:~:text=The%20penalties%20for%20selling%20Schedule,fine%20of%20up%20to%20%24250%2C000 `,
		linkName: `Pittsburg Criminal Attorney`,
		link2: `https://www.greenentrepreneur.com/article/296559 `,
		linkName2: `Green Entrepreneur`,
		color: c1
	},
	{
		id: 7,
		shortInfo: `The ‘Crack Baby’ scare was largely a myth`,
		fullInfo: `“During the 80's and 90's, the nation's health specialists panicked over the growing number of so-called "crack babies" — children exposed to crack cocaine in utero. These children were said to be doomed to lives of physical and mental disability...But it turns out that children who are exposed to crack cocaine before birth are proving these worst case scenarios were all wrong.” \n In fact, there have been numerous studies done on the topic, and one such article from the National Institute of Health explains that “Although there is a general consensus that PCE (prenatal cocaine exposure) does influence certain aspects of the growth and development of infants, children and adolescents, projections regarding the severity and prevalence of teratogenic effects that were anticipated over two decades ago have not materialized.”`,
		link: `https://www.npr.org/templates/story/story.php?storyId=126478643`,
		linkName: `National Public Radio`,
		link2: `https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4143247/ `,
		linkName2: `National Institutes of Health`,
		color: c2
	},
	{
		id: 8,
		shortInfo: `Portugal decriminalized all drugs in 2001 and has seen incredible results`,
		fullInfo: `Before this new policy was enacted, Portugal criminalized drugs much like we do in the United States. They also had a huge problem with a large number of heroin users in particular. “An estimated 1% of the population—bankers, students, socialites—were hooked on heroin and Portugal had the highest rate of HIV infection in the entire European Union.” \n However, since their widespread decriminalization, “the drug-induced death rate has plummeted to five times lower than the E.U. average and stands at one-fiftieth of the United States’. Its rate of HIV infection has dropped from 104.2 new cases per million in 2000 to 4.2 cases per million in 2015. Drug use has declined overall among the 15- to 24-year-old population, those most at risk of initiating drug use.” \n One of the key aspects that made this decriminalization work was that money previously spent on prosecuting users went instead towards treatment programs, namely providing jobs and resources for those addicted. This encourages reintegration into society, as opposed to the barricades other countries impose by criminalizing users.`,
		link: `https://time.com/longform/portugal-drug-use-decriminalization/ `,
		linkName: `TIME`,
		color: c3
	},
	{
		id: 9,
		shortInfo: `Prohibition of drugs leads directly to potency increases and adulteration`,
		fullInfo: `This correlation is called the ‘Iron Law of Prohibition’ and logically makes sense: “Imposing substantial barriers and costs to the illicit drug supply chain creates direct pressure to minimise volume while maximising profit.” In other words, if drugs cannot be bought legally, the demand will create an illegal market. Within this illegal market, if you have a more potent drug, it is physically smaller and you can smuggle more of it. You can also cut it with additives (often dangerous chemicals), creating even more profit. \n We saw this with alcohol in the 1920s. Despite beer and wine being more popular before prohibition, hard liquor like moonshine and whiskey took over once it was enacted. “The potency of alcohol products during Prohibition is estimated to have risen by more than 150% relative to pre-Prohibition and post-Prohibition periods.” \n We also see this today with Fentanyl being sold in place of heroin or morphine, since its high potency makes it a cheaper option for dealers, despite being far more dangerous.`,
		link: `http://fileserver.idpc.net/library/IDPC-guide-3-SPA/Todays-fentanyl-crisis-prohibitions-iron-law-revisited.pdf `,
		linkName: `International Journal of Drug Policy`,
		color: c4
	},
	{
		id: 10,
		shortInfo: `Legalization of drugs does NOT mean a free-for-all`,
		fullInfo: `In fact, criminalizing drugs creates more of a free-for-all than legalization and regulation. As the Transform Drug Policy Foundation (TDPF) explains, “drugs are widely available right now in every corner of our globe, to anyone of any age. There are no manufacturing standards, no consumer protection, and no safety information or warnings. No one really knows what they are buying, the risks they face, or how much might kill them.” \n However, through legalization, we can guarantee unadulterated substances and strictly regulate who has access to them. The TDPF has outlined 5 different models of regulation, through which we can minimize the harms associated with drug use. A video explaining these models, as well as the TDPF’s website is linked below.`,
		link: `https://www.youtube.com/watch?v=i-RJPQwyjk0&feature=emb_logo`,
		linkName: `5 Models of Regulation`,
		link2: `https://transformdrugs.org/regulating-drugs/`,
		linkName2: `TDPF Website`,
		color: c5
	},
	{
		id: 11,
		shortInfo: `K2 is far more dangerous than marijuana`,
		fullInfo: `It is often called ‘spice,’ ‘synthetic marijuana,’ or ‘fake weed,’ but in actuality is an unpredictable mix of chemicals that are “sprayed onto plant materials to make them look like marijuana.” \n These chemicals vary greatly from batch to batch, making K2 very risky and unpredictable. It “has been linked to a rising number of emergency department visits and to some deaths.”`,
		link: `https://teens.drugabuse.gov/drug-facts/spice#topic-2`,
		linkName: `National Institute on Drug Abuse`,
		color: c1
	},
	{
		id: 12,
		shortInfo: `Legalizing safer drugs can decrease the harmful impact of harder substances`,
		fullInfo: `According to the Drug Policy Alliance, “Increased access to marijuana has been associated with reductions in some of the most troubling harms associated with opioids, including opioid overdose deaths and untreated opioid use disorders.” \n They go on to cite that “In states with medical marijuana access, overdose death rates are almost 25% lower than in states with no legal access to marijuana, and the reductions in overdose death rates strengthened over time.”`,
		link: `https://drugpolicy.org/legalization-status-report`,
		linkName: `Drug Policy Alliance`,
		color: c2
	},
	{
		id: 13,
		shortInfo: `Alcohol withdrawal can be deadly`,
		fullInfo: `While most commonly used drugs, including heroin, cocaine, and methamphetamine can cause serious discomfort during withdrawal (nausea, anxiety, dehydration, etc.), withdrawal from alcohol and benzodiazepines can be severe enough to kill.`,
		link: `https://www.psychologytoday.com/us/blog/all-about-addiction/201001/alcohol-benzos-and-opiates-withdrawal-might-kill-you `,
		linkName: `Psychology Today`,
		color: c3
	},
	{
		id: 14,
		shortInfo: `Marijuana can be addictive`,
		fullInfo: `Despite the popular claim that it is impossible to become addicted to marijuana, studies have shown that if a person uses it often enough, they can form a dependence on it. This occurs “when the person cannot stop using the drug even though it interferes with many aspects of his or her life.” \n That being said, marijuana does have a much lower addictive potential than harder substances, with estimates that “9% of people who use marijuana will become dependent on it, rising to about 17% in those who start using in their teens.”`,
		link: `https://www.drugabuse.gov/publications/research-reports/marijuana/marijuana-addictive`,
		linkName: `National Institute on Drug Abuse`,
		color: c4
	},
	{
		id: 15,
		shortInfo: `~15 million people in the US have Alcohol Use Disorder (AUD)`,
		fullInfo: `Defined by the National Institutes of Health as a “chronic relapsing brain disorder characterized by an impaired ability to stop or control alcohol use despite adverse social, occupational, or health consequences,” AUD affects nearly 5% of the US’ population.\n Linked below is the NIH’s article, complete with questions they use to assess if a person is likely to have AUD, as well as links to treatment programs.`,
		link: `https://www.niaaa.nih.gov/alcohols-effects-health/alcohol-use-disorder`,
		linkName: `National Institutes of Health`,
		color: c5
	},
	{
		id: 16,
		shortInfo: `The US has 5% of the world’s population, but 25% of the world’s prisoners`,
		fullInfo: `This disproportionate amount of arrests is directly tied to the War on Drugs, and in particular to the mandatory minimums and other harsh punishments put in place by Richard Nixon and Ronald Reagan. “There were more than 1.5 million drug arrests in the U.S. in 2016. The vast majority – more than 80% – were for possession only.”\n Not only is this itself an important issue, but the racial bias with which these convictions are made is unbelievable. In fact, “research shows that prosecutors are twice as likely to pursue a mandatory minimum sentence for black people as for white people charged with the same offense.” \n Michelle Alexander, author of "The New Jim Crow," explains that “Nothing has contributed more to the systematic mass incarceration of people of color in the United States than the War on Drugs.”`,
		link: `https://drugpolicy.org/resource/drug-war-mass-incarceration-and-race-englishspanish `,
		linkName: `Drug Policy Alliance`,
		color: c1
	},
	{
		id: 17,
		shortInfo: `Once labeled a ‘felon,’ it is incredibly difficult to find a job or purchase a house`,
		fullInfo: `Millions of people every year are put in prison for non-violent drug offences. Not only are they locked in cages which inherently traumatize them even more (exacerbating the reason for drug use to begin with), but barricades are also put in place which make it much harder for them to become re-integrated into society once they are released. The title ‘felon’ “carries with it potentially mandatory restrictions on jobs, housing, education and public assistance.” \n This punishment and contempt with which we treat addicts does not address the root of the issue. In fact, it makes their chances of recovery worse, as it leaves them feeling isolated and hated, with little hope for a better future. \n Instead, we could be trying to help them find work, a home, or whatever they need. As Portugal did when they decriminalized all drugs in 2001, we could funnel the money we currently spend prosecuting users into programs that assimilate them back into society, giving them a sense of purpose and belonging. As Johann Hari, author of Chasing the Scream, has explained, “The opposite of addiction is not sobriety. The opposite of addiction is connection.”`,
		link: `https://www.washingtonpost.com/posteverything/wp/2014/09/30/our-criminal-justice-system-is-making-it-really-hard-for-the-poor-to-find-jobs/ `,
		linkName: `Washington Post`,
		link2: `https://time.com/longform/portugal-drug-use-decriminalization/`,
		linkName2: `TIME`,
		color: c2
	}
]

let viewMode = 'shuffle',
	displayFullFactFlag = false,
	selectedFact = null,
	firstView = true,
	prevRandomNum = null

const NUMBER_OF_FACTS = 17

let randomFact = mainArray[Math.floor(Math.random() * NUMBER_OF_FACTS)]

function Home(timer) {
	let [state, setState] = useState([])

	useEffect(() => {
		getNewFact()
	}, [timer])

	function getNewFact() {
		if (!displayFullFactFlag) {
			let newRandomNum = Math.floor(Math.random() * NUMBER_OF_FACTS)
			if (newRandomNum === prevRandomNum) {
				console.log('SAME RANDOM NUM:', newRandomNum)
				if (newRandomNum === 0) {
					newRandomNum += 1
				} else {
					newRandomNum -= 1
				}
				console.log('NEW RANDOM NUM IS NOW:', newRandomNum)
			}
			randomFact = mainArray[newRandomNum]
			console.log('newRandomNum:', newRandomNum)
			prevRandomNum = newRandomNum
			setState([...state])
		}
	}

	function displayFullFact(objIn) {
		selectedFact = objIn
		displayFullFactFlag = true
		setState([state])
	}

	function closeFullFact() {
		displayFullFactFlag = false
		setState([state])
	}

	function closeFirstView() {
		firstView = false
		setState([state])
	}

	function setViewMode(newMode) {
		if (newMode !== viewMode) {
			viewMode = newMode
			if (newMode === 'shuffle') {
				document.getElementById('shuffle').classList.add('iconSelected')
				document.getElementById('list').classList.remove('iconSelected')
			} else {
				document.getElementById('shuffle').classList.remove('iconSelected')
				document.getElementById('list').classList.add('iconSelected')
			}
		}
		setState([state])
	}

	return (
		<div className='homePageContainer'>
			{firstView && (
				<div className='firstView'>
					<div className='firstViewBox'>
						<p className='firstViewMessage'>Dear reader,</p>
						<p className='firstViewMessage'>
							This website was created as a final project for a course on the
							War on Drugs. One of the most shocking things I discovered in this
							course was the prevalence of misinformation when it comes to drug
							use and addiction. I realized that much of the information I had
							previously believed regarding drug use turned out to be factually
							unsupported or just plain incorrect. However, many of these
							beliefs still dominate the public opinion.
						</p>
						<p className='firstViewMessage'>
							The information on this site is intended to offer supported
							evidence on topics that are commonly misrepresented. My goal is to
							inspire you to think twice about your assumptions, and hopefully
							motivate you to research this information more thoroughly on your
							own.
						</p>
						<p className='firstViewMessage'>
							The facts on the homepage are fairly short, but each can be
							clicked on to provide a deeper explanation and a link to the
							source. In addition, the resources tab provides multiple links to
							different books and websites that are helpful, as well as
							treatment options for anyone in need.
						</p>
						<p className='firstViewMessage'>- Austin</p>
					</div>
					<i className='fa fa-close closeIcon' onClick={closeFirstView}></i>
				</div>
			)}
			{displayFullFactFlag && (
				<div className='fullFactPage'>
					<div className='fullFactContainer'>
						<div
							className='fullFactBox'
							style={{ backgroundColor: selectedFact.color }}
						>
							<div className='shortInfo'>{selectedFact.shortInfo}</div>
							<div>
								{selectedFact.fullInfo.split('\n').map(segment => (
									<p className='fullInfo'>{segment}</p>
								))}
							</div>
							{selectedFact.link2 ? (
								<a href={selectedFact.link} target='_blank' className='source'>
									{`Sources: ${selectedFact.linkName}`}
								</a>
							) : (
								<a href={selectedFact.link} target='_blank' className='source'>
									{`Source: ${selectedFact.linkName}`}
								</a>
							)}

							{selectedFact.link2 ? (
								<a href={selectedFact.link2} target='_blank' className='source'>
									{selectedFact.linkName2}
								</a>
							) : (
								''
							)}
						</div>
					</div>
					<i className='fa fa-close closeIcon' onClick={closeFullFact}></i>
				</div>
			)}
			<div className='headerContainer'>
				<div
					className={
						viewMode === 'shuffle' ? 'didYouKnowTextBig' : 'didYouKnowTextSmall'
					}
				>
					DID YOU KNOW?
				</div>
				<div className='iconContainer'>
					<i
						id='shuffle'
						className={
							viewMode === 'shuffle'
								? 'fa fa-random icon iconSelected'
								: 'fa fa-random icon'
						}
						onClick={() => setViewMode('shuffle')}
					></i>
					<i
						id='list'
						className={
							viewMode === 'list'
								? 'fa fa-list icon iconSelected'
								: 'fa fa-list icon '
						}
						onClick={() => setViewMode('list')}
					></i>
				</div>
			</div>
			<div className='allFactsContainer'>
				{viewMode === 'list' &&
					mainArray.map(objIn => (
						<div
							className='factContainer'
							style={{ backgroundColor: `${objIn.color}` }}
							onClick={() => displayFullFact(objIn)}
						>
							{objIn.shortInfo}
						</div>
					))}
				{viewMode === 'shuffle' && (
					<div className='shuffleModeContainer'>
						<div
							className='factContainer'
							onClick={() => displayFullFact(randomFact)}
							style={{ backgroundColor: `${randomFact.color}` }}
						>
							{randomFact.shortInfo}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default Home
