import React, { useState, useEffect } from 'react'

let helpfulLinksFlag = true,
	bibliographyFlag = false

let bibliography = `
Bajekal, N. (2018, August 1). Want to Win the War on Drugs? Portugal Might Have the Answer. TIME. Retrieved from https://time.com/longform/portugal-drug-use-decriminalization/ 
Beletsky, L. & Davis, C.S. (2017, May 28).Today’s fentanyl crisis: Prohibition’s Iron Law, revisited. International Journal of Drug Policy. Retrieved from http://fileserver.idpc.net/library/IDPC-guide-3-SPA/Todays-fentanyl-crisis-prohibitions-iron-law-revisited.pdf 
Blanks, J. (2014, September 30). Our criminal justice system is making it really hard for people to find jobs. Washington Post. Retrieved from https://www.washingtonpost.com/posteverything/wp/2014/09/30/our-criminal-justice-system-is-making-it-really-hard-for-the-poor-to-find-jobs/ 
Drug Policy Alliance. (2018, January 22). From Prohibition to Progress: A Status Report on Marijuana Legalization. Retrieved from https://drugpolicy.org/legalization-status-report 
Drug Policy Alliance. (2018, January 25). The Drug War, Mass Incarceration and Race. Retrieved from https://drugpolicy.org/resource/drug-war-mass-incarceration-and-race-englishspanish 
England, D.C. (2020). Crack vs. Powder Cocaine: One Drug, Two Penalties. CriminalDefenseLawyer. Retrieved from https://www.criminaldefenselawyer.com/resources/crack-vrs-powder-cocaine-one-drug-two-penalties.htm
Entrepreneur. (2017, June 29). What Is Schedule I and Why Is Marijuana on the List, Anyway? Green Entrepreneur. Retrieved from https://www.greenentrepreneur.com/article/296559
Hari, J. (2015, July 9). Everything you think you know about addiction is wrong. TED. Retrieved from https://www.youtube.com/watch?v=PY9DcIMGxMs 
Jaffe, A. (2010, January 13). Alcohol, Benzos, and Opiates—Withdrawal That Might Kill You. Psychology Today. Retrieved from https://www.psychologytoday.com/us/blog/all-about-addiction/201001/alcohol-benzos-and-opiates-withdrawal-might-kill-you 
Lambert, B. L., & Bauer, C. R. (2012). Developmental and behavioral consequences of prenatal cocaine exposure: a review. Journal of Perinatology. Retrieved from https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4143247/ 
MethOIDE. Routes of Administration and Drug Dependence. University of Arizona. Retrieved from https://methoide.fcm.arizona.edu/infocenter/index.cfm?stid=208 
Mitchel, M. (2010, May 3). Crack Babies: Twenty Years Later. NPR. Retrieved from https://www.npr.org/templates/story/story.php?storyId=126478643
NIAAA. (2020, November 25). Alcohol Use Disorder. NIH. Retrieved from https://www.niaaa.nih.gov/alcohols-effects-health/alcohol-use-disorder
NIDA. (2019, February 28). Fentanyl DrugFacts. Retrieved from https://www.drugabuse.gov/publications/drugfacts/fentanyl
NIDA. (2020, July 2). Is marijuana addictive? Retrieved from https://www.drugabuse.gov/publications/research-reports/marijuana/marijuana-addictive
NIDA. (2020, September 8). Spice. Retrieved from https://teens.drugabuse.gov/drug-facts/spice
TDPF. (2020). Regulation. Retrieved from https://transformdrugs.org/regulating-drugs/ 
United Nations. (2020, June). World Drug Report 2020. UNODC. Retrieved from https://wdr.unodc.org/wdr2020/field/WDR20_Booklet_2.pdf 
`

function Resources() {
	let [state, setState] = useState([])

	useEffect(() => {
		chooseSubMenu('helpfulLinks')
	}, [])

	function chooseSubMenu(subMenuName) {
		document.getElementById('resourcesPageSection').classList.add('fadeIn')

		setTimeout(() => {
			document.getElementById('resourcesPageSection').classList.remove('fadeIn')
		}, 300)

		resetSubMenu()
		if (subMenuName === 'helpfulLinks') helpfulLinksFlag = true
		if (subMenuName === 'bibliography') bibliographyFlag = true

		document
			.getElementById(`${subMenuName}Link`)
			.classList.add('resourcesSubMenuSelected')

		setState([...state])
	}

	function resetSubMenu() {
		helpfulLinksFlag = false
		bibliographyFlag = false

		document
			.getElementById(`helpfulLinksLink`)
			.classList.remove('resourcesSubMenuSelected')
		document
			.getElementById(`bibliographyLink`)
			.classList.remove('resourcesSubMenuSelected')
	}

	return (
		<div className='resourcesPage'>
			<div className='resourcesSubMenu'>
				<div
					id='helpfulLinksLink'
					className='resourcesSubMenuItem resourcesSubMenuSelected'
					onClick={() => chooseSubMenu('helpfulLinks')}
				>
					Helpful Links
				</div>
				<div
					id='bibliographyLink'
					className='resourcesSubMenuItem'
					onClick={() => chooseSubMenu('bibliography')}
				>
					Bibliography
				</div>
			</div>
			<div id='resourcesPageSection'>
				{helpfulLinksFlag && (
					<div className='linksPage'>
						<div className='sectionContainer'>
							<div className='heading'>Treatment Options</div>
							<a
								href='https://www.samhsa.gov/find-help/national-helpline'
								target='_blank'
								className='links'
							>
								- SAMHSA
							</a>
							<a
								href='https://www.addictioncenter.com/treatment/'
								target='_blank'
								className='links'
							>
								- Addiction Center
							</a>
						</div>
						<div className='sectionContainer'>
							<div className='heading'>Recommended Books</div>
							<a
								href='https://www.amazon.com/Chasing-Scream-Opposite-Addiction-Connection/dp/1620408910/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1606545381&sr=1-1'
								target='_blank'
								className='links'
							>
								- Chasing the Scream
							</a>
							<a
								href='https://www.amazon.com/New-Jim-Crow-Incarceration-Colorblindness/dp/1620971933/ref=sr_1_2?crid=1GHXUC02F3LIV&dchild=1&keywords=the+new+jim+crow&qid=1606545449&s=books&sprefix=the+new+j%2Cstripbooks%2C177&sr=1-2'
								target='_blank'
								className='links'
							>
								- The New Jim Crow
							</a>
							<a
								href='https://www.amazon.com/dp/0199588716?tag=fivebooks001-20'
								target='_blank'
								className='links'
							>
								- The Globalization of Addiction
							</a>
						</div>
						<div className='sectionContainer'>
							<div className='heading'>Recommended Videos</div>
							<a
								href='https://www.youtube.com/watch?v=wJUXLqNHCaI&list=PLNVtQ8ZL3sduMGn8XE4DjCiLTfcdI_m9V&index=74'
								target='_blank'
								className='links'
							>
								- Kurzgesagt: Why The War on Drugs Is a Huge Failure
							</a>
							<a
								href='https://www.youtube.com/watch?v=PY9DcIMGxMs'
								target='_blank'
								className='links'
							>
								- TED: Everything you think you know about addiction is wrong |
								Johann Hari
							</a>
						</div>
						<div className='sectionContainer'>
							<div className='heading'>Additional Websites</div>
							<a
								href='https://chasingthescream.com/getinvolved/'
								target='_blank'
								className='links'
							>
								- Chasing the Scream: Get Involved Site
							</a>
							<a
								href='https://www.brucekalexander.com/'
								target='_blank'
								className='links'
							>
								- Bruce Alexander's Site
							</a>
							<a
								href='https://drcarlhart.com/'
								target='_blank'
								className='links'
							>
								- Dr. Carl Hart's Site
							</a>
						</div>
					</div>
				)}
				{bibliographyFlag && (
					<div className='linksPage'>
						{bibliography
							.trim()
							.split('\n')
							.map(source => (
								<div className='bibliographySectionContainer'>
									<div className='bibliographySources'>{source}</div>
								</div>
							))}
					</div>
				)}
			</div>
		</div>
	)
}

export default Resources
